<template>
  <div
    class="category-container p-4 bg-white flex items-center justify-between w-full rounded-lg"
  >
    <div class="flex items-center w-full">
      <el-image
        :src="info.header || require('../assets/image/cover.png')"
        class="cover mr-3"
        fit="cover"
      >
      </el-image>
      <div class="activity-info flex-1">
        <div class="activity-name text-base font-bold">
          {{ info.name }}
        </div>
        <!-- <div class="activity-desc" v-if="info.name === 'Others'">
          <div
            class="desc text-sm content"
            :style="{ lineClamp: info.submission_ddl ? 2 : 3 }"
          >
            {{ info.intro }}
          </div>
        </div> -->
        <!-- <div class="mt-2" v-if="info.submission_ddl">
          <div class="time mb-1">Entry deadline:</div>
          <div
            class="flex items-center"
            v-if="!checkSubmissionEnded(info.submission_ddl)"
          >
            <div :class="['time', { nearBy: info.nearBy }]">
              {{ $dayjs(info.submission_ddl * 1000).format("MMMM D, YYYY") }}
            </div>
          </div>
          <p
            v-else
            class="common-tag"
            style="color: #999; background-color: #ccc"
          >
            Closed for Submission
          </p>
        </div> -->
      </div>
      <div class="favorite" @click.stop="onCollect">
        <i
          class="el-icon-star-off"
          style="color: #999"
          v-if="!info.collect"
        ></i>
        <i class="el-icon-star-on text-yellow-500" v-else></i>
      </div>
    </div>
  </div>
</template>

<script>
import { collectCategory, cancelCollectCategory } from "../api/eae";
import { getUserId, getUserType } from "../utils/store";
export default {
  name: "AdvisorCategory",
  props: {
    info: Object,
    index: Number,
    showShareBtn: String,
  },
  mounted() {},
  methods: {
    handleView() {
      this.$emit("view");
    },
    checkSubmissionEnded(submission_ddl) {
      let todayStartTime = new Date(new Date().toLocaleDateString()).getTime();
      let startTime = new Date(todayStartTime - 1).getTime();
      if (startTime > submission_ddl * 1000) {
        return true;
      }
      return false;
    },

    showShare() {
      this.$emit("showShare");
    },

    async onCollect() {
      try {
        const res = this.info.collect
          ? await cancelCollectCategory({
              collect_id: this.info.collect._id.$id,
            })
          : await collectCategory({
              category_id: this.info._id.$id,
              user_id: getUserId(),
              user_type: getUserType(),
            });
        if (res.data.code === 0) {
          this.$message.success(
            this.info.collect
              ? "Cancel collect successfully."
              : "Collect successfully."
          );
          this.$emit("success");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-container {
  position: relative;
  .sort {
    margin-right: 15px;
    text-align: center;
    width: 34px;
    font-size: 16px;
    padding: 3px 0px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .cover {
    width: 72px;
    height: 72px;
  }

  .content {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .shareBtn {
    position: absolute;

    padding: 5px 14px;
    box-sizing: border-box;
    background: #ffe2e2;
    border-radius: 6px 6px 6px 6px;
    font-size: 14px;
    font-family: Century Gothic-Bold, Century Gothic;
    color: #ff6450;
    right: 0px;
  }

  .activity-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .activity-name {
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      width: 160px;
    }

    .time-icon {
      width: 18px;
      height: 18px;
      margin: 0 12px 0 0;
    }

    .time {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      &.nearBy {
        color: #ff8e3b;
      }
    }

    .activity-time {
      color: var(--c-primary);

      span {
        color: #999999;
      }

      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #666666;
    }

    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .common-tag {
    width: fit-content;
  }
  .favorite {
    position: absolute;
    font-size: 20px;
    top: 16px;
    right: 16px;
    .el-icon-star-on {
      font-size: 24px;
      top: 12px;
      right: 12px;
    }
  }
}
</style>
